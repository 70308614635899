<template>
  <div>
    <!-- Filters -->
    <b-card-actions
      title="Filters"
      no-body
      class="mb-0"
      actionRefresh
      actionCollapse
      @refresh="triggerRefresh"
    >
      <KarmaRequestListFilters
        :status-filter.sync="statusFilter"
        :status-options="karmaRequestStatusOption"
        :category-filter.sync="categoryFilter"
        :parent-category-option="parentCategoryOption"
        :karma-program-filter.sync="karmaProgramFilter"
        :karma-program-option="karmaProgramOption"
        :date-filter.sync="dateFilter"
        :employee-list-options="employeeListOptions"
        :manager-filter.sync="managerFilter"
      />
    </b-card-actions>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox class="mr-2" v-model="isDeleteMultipleCandChecked">
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedKarmaRequests.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search User Name,Category,Catalog,Status,Notes"
              />
              <b-button
                v-if="$can('karma_request_create')"
                variant="primary"
                :to="{ name: 'add-karma-request' }"
              >
                <span class="text-nowrap d-none d-md-flex">Add Karma Request</span>
 		<span class="text-nowrap d-md-none">Add</span>
              </b-button>
            </div>
            <b-button
              v-else-if="$can('karma_request_delete')"
              class="float-right"
              variant="danger"
              :disabled="karmaRequestsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ karmaRequestsDeleteCount }} Karma Request</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedKarmaRequests"
        name="selectedKarmaRequests"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchKarmaRequestList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(date)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('karma_request_show')"
                :to="{
                  name: 'view-karma-request',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-block text-nowrap"
                target="_blank"
              >
                {{ data.item.date | formatDate }}
              </b-link>
              <span v-else>
                {{ data.item.date | formatDate }}
              </span>
            </b-media>
          </template>

          <!-- Column: User -->
          <template #cell(requested_for)="data">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <b-avatar size="32" :src="data.item.requested_for.avatar" />
              </template>
              <b-link
                v-if="$can('employee_show')"
                :to="{
                  name: 'view-employee',
                  params: { id: data.item.requested_for.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.requested_for.name"
                target="_blank"
              >
                {{ data.item.requested_for.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                class="d-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.requested_for.name"
              >
                {{ data.item.requested_for.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <template #cell(karma_catalog.karma_category)="data">
            <b-media
              vertical-align="center"
              v-if="data.item.karma_catalog.karma_category"
            >
              <b-link
                v-if="$can('karma_category_show')"
                :to="{
                  name: 'view-karma-category',
                  params: { id: data.item.karma_catalog.karma_category.hashid },
                }"
                class="font-weight-bold d-block text-nowrap"
                target="_blank"
              >
                {{
                  data.item.karma_catalog.karma_category.name | str_limit(20)
                }}
              </b-link>
              <span v-else class="d-block">
                {{
                  data.item.karma_catalog.karma_category.name | str_limit(20)
                }}
              </span>
              <b-link
                v-if="$can('karma_category_show')"
                :to="{
                  name: 'view-karma-category',
                  params: {
                    id: data.item.karma_catalog.sub_karma_category.hashid,
                  },
                }"
                class="font-weight-bold d-block text-nowrap"
                target="_blank"
              >
                {{
                  data.item.karma_catalog.sub_karma_category.name
                    | str_limit(20)
                }}
              </b-link>
              <span v-else>
                {{
                  data.item.karma_catalog.sub_karma_category.name
                    | str_limit(20)
                }}
              </span>
            </b-media>
          </template>

          <template #cell(karma_catalog_id)="data">
            <b-media vertical-align="center" v-if="data.item.karma_catalog">
              <b-link
                v-if="$can('karma_catalog_show')"
                :to="{
                  name: 'view-karma-catalog',
                  params: { id: data.item.karma_catalog.hashid },
                }"
                class="font-weight-bold d-block text-nowrap"
                target="_blank"
              >
                {{ data.item.karma_catalog.karma_name | str_limit(20) }}
              </b-link>
              <span v-else>
                {{ data.item.karma_catalog.karma_name | str_limit(20) }}
              </span>
            </b-media>
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status.replace(/_/g, " ") }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              @click="editRecord(data)"
              v-if="$can('karma_request_edit')"
              v-b-tooltip.hover
              title="Edit"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
            />
            <feather-icon
              @click="viewRecord(data)"
              v-if="$can('karma_request_show')"
              v-b-tooltip.hover
              title="View"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              v-if="$can('karma_request_delete')"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('karma_request_delete')"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="selectedKarmaRequests.length == 0"
              v-model="currentPage"
              :total-rows="totalKarmaRequestList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('karma_request_delete')"
              class="float-right"
              variant="danger"
              :disabled="karmaRequestsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ karmaRequestsDeleteCount }} Karma Request</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import karmaRequestStoreModule from "../karmaRequestStoreModule";
import useKarmaRequestList from "./useKarmaRequestList";
import KarmaRequestListFilters from "./KarmaRequestListFilters.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";

export default {
  components: {
    KarmaRequestListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
    BCardActions,
    BCardActionsContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-karma-requests";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedKarmaRequests = ref([]);
    const karmaRequestsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        karmaRequestStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchKarmaRequestList,
      tableColumns,
      perPage,
      currentPage,
      totalKarmaRequestList,
      karmaRequestsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByKarmaRequestUrgencyStatus,
      filterByKarmaRequestType,
      resolveUserStatusVariant,
      karmaRequestStatusOption,
      categoryFilter,
      parentCategoryOption,
      karmaProgramFilter,
      karmaProgramOption,
      dateFilter,
      managerFilter,
    } = useKarmaRequestList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedKarmaRequests.value = [];
      console.log("is multiple delete checked");
      if (val) {
        console.log(karmaRequestsMetaData.value);
        karmaRequestsMetaData.value.forEach((karma_request) => {
          selectedKarmaRequests.value.push(karma_request.hashid);
        });
      }
    });

    watch(selectedKarmaRequests, (val) => {
      karmaRequestsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchKarmaRequestList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalKarmaRequestList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByKarmaRequestUrgencyStatus,
      filterByKarmaRequestType,
      selectedKarmaRequests,
      karmaRequestsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      karmaRequestStatusOption,
      categoryFilter,
      parentCategoryOption,
      karmaProgramFilter,
      karmaProgramOption,
      dateFilter,
      managerFilter,
    };
  },
  data() {
    return {
      employeeListOptions:[]
    };
  },
  created() {
    this.descendantMangers();
  },
  methods: {
    descendantMangers() {
      this.$store
        .dispatch("app/descendantMangers")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    triggerRefresh() {
      this.categoryFilter = null;
      this.statusFilter = [];
      this.karmaProgramFilter = null;
      this.dateFilter = null;
      this.managerFilter = null;
    },
    editRecord(data) {
      const routePath = `/edit/karma-request/${data.item.hashid}`; // Replace with the desired route path
      const url = `${window.location.origin}${this.$router.resolve(routePath).href}`;
      window.open(url, '_blank');

      // this.$router
      //   .push(`/edit/karma-request/${data.item.hashid}`)
      //   .catch(() => {});
    },
    viewRecord(data) {
      const routePath = `/view/karma-request/${data.item.hashid}`; // Replace with the desired route path
      const url = `${window.location.origin}${this.$router.resolve(routePath).href}`;
      window.open(url, '_blank');
      // this.$router
      //   .push(`/view/karma-request/${data.item.hashid}`)
      //   .catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.date}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedKarmaRequests.length} Karma Request, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-karma-requests/removeMultipleKarmaRequests",
              this.selectedKarmaRequests
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedKarmaRequests = [];
              if (res.status == "204") {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully.",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Karma Request Deletion failed.",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchKarmaRequestsData() {
      console.log("Added Karma Request");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-karma-requests/removeKarmaRequest", id)
        .then((res) => {
          if (res.status == "204") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Karma Request Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Karma Request Deleted Successfully.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Karma Request Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Karma Request Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
